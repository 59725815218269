import React, { useState } from "react";
import { Form, Input, Button, Typography, message } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { UserOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";

const Settings = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    // let reqURL = "signup";
    // let reqOBJ = values;

    // // Setup Default Values
    // reqOBJ.currency = "INR";
    // reqOBJ.language = "en-US";
    // reqOBJ.template = "Template1";
    // reqOBJ.url = values?.company?.replace(/[^a-z0-9-]/gi, "").toLowerCase();

    // fetchData(reqURL, reqOBJ, (data) => {
    //   setLoading(false);
    //   message.success("Profile updated successful!");
    // });
  };

  const layout = {
    labelCol: {
      span: 2,
    },
    wrapperCol: {
      span: 8,
    },
  };

  return (
    <div>
      <PageHeader
        title={
          <React.Fragment>
            <UserOutlined style={{ marginRight: "4px" }} /> Profile Settings
          </React.Fragment>
        }
        ghost={false}
      />

      <Form
        name="signup"
        onFinish={onFinish}
        autoComplete="off"
        className="signin-form"
        {...layout}
      >
        <Form.Item
          label="Your Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input First and Last Name!",
            },
          ]}
        >
          <Input placeholder="First and Last Name" />
        </Form.Item>
        <Form.Item
          label="Agency Name"
          name="company"
          rules={[
            {
              required: true,
              message: "Please input User Agency Name!",
            },
          ]}
        >
          <Input placeholder="Enter Agency Name" />
        </Form.Item>
        <Form.Item
          label="Email Id"
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input Email Id!",
            },
          ]}
        >
          <Input placeholder="Enter Email Id" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input Phone Number!",
            },
          ]}
        >
          <Input placeholder="Enter Phone Number" />
        </Form.Item>
        <Form.Item label="Change Password" name="password">
          <Input type="password" placeholder="Enter Password" />
        </Form.Item>
        <Form.Item
          wrapperCol={{ xs: { offset: 0 }, xl: { offset: 2 } }}
          className="btn-row"
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Update Profile
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Settings;
