import React from "react";
import HomeBanner from "../../components/website/home-banner";
import HomeWhyus from "../../components/website/home-whyus";
import HomePosts from "../../components/website/home-posts";
import HomeContact from "../../components/website/home-contact";

const WebHome = (props) => {
  return (
    <div className="web-home" style={{ minHeight: "100vh" }}>
      {props?.promotions && <HomeBanner />}
      <div className="container">
        {props?.whyus && <HomeWhyus {...props} />}
      </div>
      {props?.deals && <HomePosts {...props} category="blog" />}
      {props?.packages && <HomePosts {...props} category="service" />}
      {props?.destinations && <HomePosts {...props} category="case-study" />}
      <HomeContact />
    </div>
  );
};

export default WebHome;
