import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fetchData from "../../services/requester-website";
import parse from "html-react-parser";
import WhatsAppIcon from "../../assets/images/website/whatsapp-icon.svg";
import WebLoader from "../../components/website/web-loader";
import { imagePath } from "../../helpers/global";

const PostDetails = (props) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const getData = () => {
    const { id } = params;

    let reqURL = "posts/detail/" + id;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  const handleWhatsApp = () => {
    const { countryCode, phone } = props;

    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    let whatsApp = "";

    if (isMobile) {
      whatsApp = "whatsapp://send?phone=" + countryCode + phone + "&text=";
    } else {
      whatsApp =
        "https://web.whatsapp.com/send?phone=" + countryCode + phone + "&text=";
    }

    window.open(
      whatsApp + encodeURIComponent("I am interested: " + window.location),
      "_blank"
    );
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="web-details destination-details">
      {!loading && (
        <div className="container">
          <div className="pt-4">
            <div className="row">
              <div className="col-lg-8">
                {data?.imageURL && (
                  <div className="web-details-image">
                    <h2>{data?.postTitle}</h2>
                    <img
                      src={imagePath() + data?.imageURL}
                      alt={data?.postTitle}
                    />
                  </div>
                )}

                <div className="web-details-sec">
                  {data?.postContent && (
                    <div className="text-secondary mb-4">
                      {parse("" + data?.postContent + "")}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="web-details-info">
                  <div className="web-details-title">
                    <h2>{data?.postTitle}</h2>
                  </div>
                  <div className="web-details-info-wrap">
                    {data?.shortDescription && (
                      <div className="text-secondary mb-4">
                        {parse("" + data?.shortDescription + "")}
                      </div>
                    )}
                    <h5 className="mb-4">
                      {data?.postSubTitle}!
                      <br />
                      Connect on WhatsApp for more details
                    </h5>

                    <div className="web-details-btns">
                      <button
                        className="btn btn-whatsapp"
                        onClick={handleWhatsApp}
                      >
                        <img src={WhatsAppIcon} alt="" />
                        WhatsApp
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && <WebLoader />}
    </div>
  );
};

export default PostDetails;
