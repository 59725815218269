import React, { useState, useEffect } from "react";
import fetchData from "../../services/requester-website";
import { Link } from "react-router-dom";
import { imagePath } from "../../helpers/global";
import { defaultSettings } from "../../helpers/default-settings";
import parse from "html-react-parser";

const HomePosts = (props) => {
  const [data, setData] = useState([]);

  const getData = () => {
    let reqURL = "posts";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        data &&
          setData(
            data?.filter((x) => x?.postCategory === props.category) || []
          );
      },
      "GET"
    );
  };

  const getTitle = () => {
    let hdr = { title: "", subTitle: "" };

    if (props.category === "blog") {
      hdr.title = props?.dealsTitle || defaultSettings?.dealsTitle;
      hdr.subTitle = props?.dealsSubTitle || defaultSettings?.dealsSubTitle;
    }
    if (props.category === "service") {
      hdr.title = props?.packagesTitle || defaultSettings?.packagesTitle;
      hdr.subTitle =
        props?.packagesSubTitle || defaultSettings?.packagesSubTitle;
    }
    if (props.category === "case-study") {
      hdr.title =
        props?.destinationsTitle || defaultSettings?.destinationsTitle;
      hdr.subTitle =
        props?.destinationsSubTitle || defaultSettings?.destinationsSubTitle;
    }

    return hdr;
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    data &&
    data.length > 0 && (
      <div className={"home-posts home-posts-" + props.category}>
        <div className="container">
          <h2 className="home-title">{getTitle()?.title}</h2>
          <p className="home-sub-title">{getTitle()?.subTitle}</p>
          <div className="row">
            {data?.map(
              (x, key) =>
                key < 4 && (
                  <div key={key} className="col-lg-6">
                    <div className="home-posts-wrap">
                      <div className="row">
                        <div className="col-lg-4">
                          <img
                            src={imagePath() + x?.imageURL}
                            alt={x?.postName}
                          />
                        </div>
                        <div className="col-lg-8">
                          <h3 className="mt-2 mb-3">{x?.postName}</h3>
                          <h5>{x?.postSubTitle}</h5>
                        </div>
                      </div>

                      <Link to={props?.category + "/" + x?.postId} />
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default HomePosts;
