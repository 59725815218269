import React from "react";

const HomeContact = () => {
  return (
    <div>
      <div className="container">
        <h2 className="home-title">Contact Us:</h2>
        <p className="home-sub-title">Let's Talk About How We Can Help</p>

        <div className="row mt-5">
          <div className="col-lg-6">
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "62px",
                  height: "62px",
                  background: "rgb(245, 113, 22)",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#fff"
                  height="28px"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  ></path>
                </svg>
              </div>
              <div style={{ paddingLeft: "24px" }}>
                <p className="text-secondary mb-2">Email</p>
                <h5 style={{ fontWeight: "700" }}>actl.ceo@gmail.com</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "62px",
                  height: "62px",
                  background: "rgb(245, 113, 22)",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="#fff"
                  height="28px"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                  ></path>
                </svg>
              </div>
              <div style={{ paddingLeft: "24px" }}>
                <p className="text-secondary mb-2">Tel</p>
                <h5 style={{ fontWeight: "700" }}>+919825456140</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContact;
