import React, { useEffect, useState } from "react";
import {
  List,
  Row,
  Col,
  Button,
  Typography,
  Dropdown,
  Drawer,
  Tooltip,
  Avatar,
  Modal,
  message,
  Table,
  Tag,
} from "antd";
import {
  CalculatorOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  MenuOutlined,
  FilterOutlined,
  TableOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileImageOutlined,
  FileAddOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Date, Currency } from "../helpers/formatter";
import fetchData from "../services/requester";
import Summary from "../components/summary";
import LeadAdd from "../components/lead-add";
import LeadView from "../components/lead-view";
import AddQuickProposal from "../components/add-quick-proposal";
import CommonFilters from "../components/common-filters";

const Leads = (props) => {
  const [data, setData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const [isConfirmLoading, setConfirmLoading] = useState(false);
  const [isFiltersVisible, setFiltersVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [visibleProposal, setVisibleProposal] = useState(false);
  const [view, setView] = useState("grid");
  const { Title, Text } = Typography;

  const getData = () => {
    let reqURL = "leads";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data || []);
        setDefaultData(data || []);
        setLoading(false);
      },
      "GET"
    );
  };

  const menuItems = [
    {
      label: "View Lead",
      key: "view",
      icon: <EyeOutlined />,
    },
    {
      label: "Edit Lead",
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: "Delete Lead",
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];

  const handleMenuClick = (key, item) => {
    setDetails(item);
    switch (key) {
      case "view":
        setIsModalVisible(true);
        break;

      case "edit":
        setIsEditMode(true);
        showDrawer();
        break;

      case "delete":
        setConfirmVisible(true);
        break;

      default:
        break;
    }
  };

  const handleClick = (item) => {
    setIsModalVisible(true);
    setDetails(item);
  };

  const onClose = () => {
    setIsEditMode(false);
    setVisible(false);
  };

  const onFiltersClose = () => {
    setFiltersVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const showFiltersDrawer = () => {
    setFiltersVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAdd = (response) => {
    message.success(
      !isEditMode ? "Lead added successfully" : "Lead updated successfully"
    );
    setVisible(false);
    setIsEditMode(false);
    getData();
  };

  const handleDelete = () => {
    setConfirmLoading(true);
    const { leadId } = details;
    let reqURL = "leads/delete/" + leadId;
    let reqOBJ = "";
    fetchData(reqURL, reqOBJ, (data) => {
      setConfirmVisible(false);
      setConfirmLoading(false);
      if (data?.success) {
        message.success("Lead deleted successfully.");
        setLoading(true);
        getData();
      } else {
        message.error(data?.title || "Oops! Something went wrong.");
      }
    });
  };

  const changeView = () => {
    setView(view === "grid" ? "list" : "grid");
  };

  const handleFilters = (filters) => {
    setFiltersVisible(false);

    const filteredData = defaultData?.filter(
      ({ title, bookingFor, name, email, phone }) =>
        (!filters?.title ||
          title?.toLowerCase()?.includes(filters?.title?.toLowerCase())) &&
        (!filters?.bookingFor ||
          bookingFor
            ?.toLowerCase()
            ?.includes(filters?.bookingFor?.toLowerCase())) &&
        (!filters?.name ||
          name?.toLowerCase()?.includes(filters?.name?.toLowerCase())) &&
        (!filters?.email ||
          email?.toLowerCase()?.includes(filters?.email?.toLowerCase())) &&
        (!filters?.phone || phone?.includes(filters?.phone))
    );

    setData(filteredData);
  };

  const showProposalDrawer = (item) => {
    setDetails(item);
    setVisibleProposal(true);
  };

  const onProposalClose = () => {
    setIsEditMode(false);
    setVisibleProposal(false);
  };

  const handleProposalAdd = (response) => {
    message.success(
      !isEditMode
        ? "Quick Proposal added successfully"
        : "Quick Proposal updated successfully"
    );
    setVisibleProposal(false);
    setIsEditMode(false);
    getData();
  };

  const handleShare = (item) => {
    const { countryCode, phone, url } = props;
    const { quickProposalId } = item;

    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    let path = "/website/" + url + "/quick-proposal-details/" + quickProposalId;
    let message = "Your Proposal - ";
    let whatsApp = "";

    if (isMobile) {
      whatsApp = "whatsapp://send?phone=" + countryCode + phone + "&text=";
    } else {
      whatsApp =
        "https://web.whatsapp.com/send?phone=" + countryCode + phone + "&text=";
    }

    window.open(
      whatsApp + encodeURIComponent(message + window.origin + path),
      "_blank"
    );
  };

  const handlePreview = (item) => {
    const { url } = props;
    const { quickProposalId } = item;
    let path = "/website/" + url + "/quick-proposal-details/" + quickProposalId;

    window.open(window.origin + path, "_blank");
  };

  const columns = [
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>{text}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text, record) => record?.countryCode + text,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Lead Type",
      dataIndex: "leadType",
      key: "leadType",
    },
    {
      title: "Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => Date(text),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (text) => Currency(text),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Created",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => Date(text),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div className="d-flex align-items-center justify-content-center">
          <React.Fragment>
            {record?.quickProposalId && (
              <Tooltip placement="bottom" title={"Update Proposal"}>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => showProposalDrawer(record)}
                  className="reset-icon-btn"
                />
              </Tooltip>
            )}

            {!record?.quickProposalId && (
              <Tooltip placement="bottom" title={"Add Proposal"}>
                <Button
                  icon={<FileAddOutlined />}
                  onClick={() => showProposalDrawer(record)}
                  className="reset-icon-btn"
                />
              </Tooltip>
            )}
          </React.Fragment>
          <Dropdown
            menu={{
              items: menuItems,
              onClick: (e) => handleMenuClick(e.key, record),
            }}
            icon
          >
            <Button className="reset-icon-btn">
              <MenuOutlined />
            </Button>
          </Dropdown>
        </div>
      ),
      width: 50,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <React.Fragment>
            <MailOutlined style={{ marginRight: "4px" }} /> Leads
          </React.Fragment>
        }
        extra={[
          <Tooltip title="Change View" placement="bottom" key={1}>
            <Button
              key={1}
              type="default"
              icon={
                view === "grid" ? <TableOutlined /> : <UnorderedListOutlined />
              }
              onClick={changeView}
            >
              Views
            </Button>
          </Tooltip>,
          <Tooltip title="Filters" placement="bottom" key={2}>
            <Button
              key={2}
              type="default"
              icon={<FilterOutlined />}
              onClick={showFiltersDrawer}
            >
              Filters
            </Button>
          </Tooltip>,
          <Button
            key={3}
            type="primary"
            icon={<PlusOutlined />}
            onClick={showDrawer}
          >
            Add Lead
          </Button>,
        ]}
        ghost={false}
      />

      {view === "grid" && (
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={data}
          className="general-list"
          renderItem={(item) => (
            <List.Item
              actions={[
                <React.Fragment>
                  {item?.quickProposalId && (
                    <Tooltip placement="bottom" title={"Update Proposal"}>
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => showProposalDrawer(item)}
                        className="reset-icon-btn"
                      />
                    </Tooltip>
                  )}
                  {!item?.quickProposalId && (
                    <Tooltip placement="bottom" title={"Add Proposal"}>
                      <Button
                        icon={<FileAddOutlined />}
                        onClick={() => showProposalDrawer(item)}
                        className="reset-icon-btn"
                      />
                    </Tooltip>
                  )}
                </React.Fragment>,
                <Dropdown
                  menu={{
                    items: menuItems,
                    onClick: (e) => handleMenuClick(e.key, item),
                  }}
                  icon
                >
                  <Button
                    className="reset-icon-btn"
                    style={{ marginLeft: "16px" }}
                  >
                    <MenuOutlined />
                  </Button>
                </Dropdown>,
              ]}
            >
              <Row style={{ width: "100%" }}>
                <Col
                  flex="auto"
                  className="lead-col-1"
                  onClick={() => handleClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  <Row gutter={16}>
                    <Col
                      flex="none"
                      style={{ display: "flex", alignItems: "center" }}
                      className="lead-avatar"
                    >
                      <Avatar size={52} shape="square">
                        <span
                          style={{
                            fontSize: "18px",
                            textTransform: "uppercase",
                            lineHeight: "18px",
                          }}
                        >
                          {item?.name?.charAt(0)}
                        </span>
                      </Avatar>
                    </Col>
                    <Col flex="auto">
                      <Title
                        level={5}
                        style={{
                          textTransform: "capitalize",
                          margin: "0px 0px 6px 0px",
                        }}
                      >
                        {item?.name}
                      </Title>

                      <Text type="secondary">
                        <MailOutlined /> {item?.email}
                      </Text>
                      <Text type="secondary" style={{ marginLeft: "16px" }}>
                        <PhoneOutlined /> {item?.countryCode + item?.phone}
                      </Text>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xs={24}
                  xl={9}
                  className="lead-col-2"
                  onClick={() => handleClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  <Text
                    strong
                    style={{
                      display: "block",
                      marginBottom: "6px",
                      textTransform: "capitalize",
                    }}
                  >
                    {item.title}
                  </Text>
                  <Text type="secondary">
                    <CalendarOutlined /> {Date(item?.startDate)}
                  </Text>
                  <Text type="secondary" style={{ marginLeft: "16px" }}>
                    <ClockCircleOutlined /> {item?.duration} days
                  </Text>
                  <Text type="secondary" style={{ marginLeft: "16px" }}>
                    <CalculatorOutlined /> {Currency(item?.budget)}
                  </Text>
                  <Text type="secondary" style={{ marginLeft: "16px" }}>
                    <FileImageOutlined /> {item?.leadType}
                  </Text>
                </Col>

                <Col xs={24} xl={4} className="lead-col-3">
                  <div style={{ display: "flex", marginBottom: "6px" }}>
                    <Tag
                      color={
                        item?.status === "New"
                          ? "blue"
                          : item?.status === "Proposal"
                          ? "green"
                          : "gold"
                      }
                    >
                      {item?.status}
                    </Tag>

                    {item?.quickProposalId && (
                      <div style={{ display: "flex", marginLeft: "8px" }}>
                        <Tooltip placement="bottom" title={"Share on WhatsApp"}>
                          <Button
                            icon={
                              <WhatsAppOutlined
                                style={{ fontSize: "16px", color: "#25d366" }}
                              />
                            }
                            onClick={() => handleShare(item)}
                            style={{
                              border: "0px",
                              background: "none",
                              boxShadow: "none",
                              display: "flex",
                              height: "20px",
                            }}
                          />
                        </Tooltip>
                        <Tooltip placement="bottom" title={"Preview"}>
                          <Button
                            icon={<EyeOutlined style={{ fontSize: "18px" }} />}
                            onClick={() => handlePreview(item)}
                            style={{
                              border: "0px",
                              background: "none",
                              boxShadow: "none",
                              display: "flex",
                              height: "20px",
                            }}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <Text
                    style={{ textTransform: "capitalize" }}
                    type="secondary"
                  >
                    Created: {Date(item?.createdDate)}
                  </Text>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      )}

      {view === "list" && (
        <Table
          columns={columns}
          rowKey={(record) => record?.leadId}
          dataSource={data || []}
          loading={loading}
          size="small"
        />
      )}

      <Drawer
        title={!isEditMode ? "Add Lead" : "Update Lead"}
        placement="right"
        onClose={onClose}
        open={visible}
        size={"large"}
      >
        <LeadAdd
          isEditMode={isEditMode}
          {...details}
          key={visible}
          handleAdd={handleAdd}
        />
      </Drawer>

      <Drawer
        title="Filters"
        placement="right"
        onClose={onFiltersClose}
        open={isFiltersVisible}
      >
        <CommonFilters handleFilters={handleFilters} screen={"leads"} />
      </Drawer>

      <Drawer
        title={!isEditMode ? "Add Quick Proposal" : "Update Quick Proposal"}
        placement="right"
        onClose={onProposalClose}
        open={visibleProposal}
        size={"large"}
      >
        <AddQuickProposal
          isEditMode={isEditMode}
          {...details}
          portal={props}
          key={visibleProposal}
          handleAdd={handleProposalAdd}
          from={"leads"}
        />
      </Drawer>

      <Modal
        title={
          <span style={{ textTransform: "capitalize" }}>
            {details?.name + " " + " - " + details?.title}
          </span>
        }
        footer={null}
        centered
        open={isModalVisible}
        onCancel={handleCancel}
        key={isModalVisible}
        width={720}
      >
        <LeadView {...details} key={isModalVisible} />
      </Modal>

      <Modal
        title={"Confirm Delete"}
        open={isConfirmVisible}
        onCancel={() => setConfirmVisible(false)}
        centered
        width={320}
        footer={[
          <div style={{ textAlign: "center" }} key="delete">
            <Button
              type="primary"
              loading={isConfirmLoading}
              danger
              onClick={() => handleDelete()}
            >
              Confirm
            </Button>
          </div>,
        ]}
      >
        <div style={{ margin: "16px 0px" }}>
          Are you sure to delete this Item?
        </div>
      </Modal>
    </div>
  );
};

export default Leads;
