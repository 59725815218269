import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  WhatsAppOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import fetchData from "../services/requester";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadImg from "../components/upload-img";
import { Date, Time } from "../helpers/formatter";
import CustomersDropDown from "./customers-dropdown";
import dayjs from "dayjs";

const AddQuickProposal = (props) => {
  const [data, setData] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [quickReplies, setQuickReplies] = useState([]);
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [customerType, setCustomerType] = useState("Individual");

  const getData = () => {
    const { quickProposalId } = props;
    let reqURL = "quick-proposals/detail/" + quickProposalId;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data && data?.length > 0) {
          setData(data[0] || "");
          setDefaultData(data[0] || "");
          getQuickReplies();
        }
      },
      "GET"
    );
  };

  const setDefaultData = (data) => {
    form.setFieldsValue({
      title: data?.title,
      details: data?.details,
      offerPrice: data?.offerPrice,
      imageURL: data?.imageURL,
    });
  };

  const onFinish = (values) => {
    setLoading(true);
    const { leadId, customerId, quickProposalId } = props;

    values.leadId = leadId;
    values.customerId = customerId;

    let reqURL = "quick-proposals/" + (isEditMode ? "update" : "add");
    let reqOBJ = values;

    isEditMode && (reqOBJ.quickProposalId = quickProposalId);

    fetchData(reqURL, reqOBJ, (data) => {
      data?.success
        ? props.handleAdd()
        : message.error("Oops! Something went wrong.");

      setLoading(false);
    });
  };

  const addWithLead = (values) => {
    setLoading(true);

    let reqURL = "quick-proposals/add-with-lead";
    let reqOBJ = values;

    reqOBJ.customerType = "Individual";
    reqOBJ.birthDate = "2000-01-01T00:00:00.000Z";
    reqOBJ.expiryDate = "2000-01-01T00:00:00.000Z";
    reqOBJ.leadType = "Package";
    reqOBJ.tripType = "Domestic";
    reqOBJ.bookingFor = "Individual";
    reqOBJ.status = "Proposal";
    reqOBJ.startDate = dayjs().add(2, "day");
    reqOBJ.duration = 2;
    reqOBJ.source = "From Proposal";
    reqOBJ.budget = values?.offerPrice || 0;
    reqOBJ.followupDate = dayjs().add(1, "day");
    reqOBJ.adults = 1;
    reqOBJ.assignedTo = "Sales";

    fetchData(reqURL, reqOBJ, (data) => {
      data?.success
        ? props.handleAdd()
        : message.error("Oops! Something went wrong.");

      setLoading(false);
    });
  };

  const handleUpload = (imageURL) => {
    form.setFieldsValue({ imageURL });
  };

  const handleShare = () => {
    const { countryCode, phone, portal, quickProposalId } = props;
    const { url } = portal;

    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    let path = "/website/" + url + "/quick-proposal-details/" + quickProposalId;
    let message = "Your Proposal - ";
    let whatsApp = "";

    if (isMobile) {
      whatsApp = "whatsapp://send?phone=" + countryCode + phone + "&text=";
    } else {
      whatsApp =
        "https://web.whatsapp.com/send?phone=" + countryCode + phone + "&text=";
    }

    window.open(
      whatsApp + encodeURIComponent(message + window.origin + path),
      "_blank"
    );
  };

  const handlePreview = () => {
    const { portal, quickProposalId } = props;
    const { url } = portal;
    let path = "/website/" + url + "/quick-proposal-details/" + quickProposalId;
    window.open(window.origin + path, "_blank");
  };

  const getQuickReplies = () => {
    const { quickProposalId } = props;
    let reqURL = "quick-proposals/quick-replies/" + quickProposalId;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setQuickReplies(data || []);
      },
      "GET"
    );
  };

  const handleCustomer = (customer) => {
    form.setFieldsValue({ customerId: customer });
  };

  useEffect(() => {
    if (props?.from === "leads") {
      props?.quickProposalId && getData();
      props?.quickProposalId && setIsEditMode(true);
    }
    if (props?.from === "quick-add") {
      props?.isEditMode && getData();
      setIsEditMode(props?.isEditMode);
    }
  }, []);

  return (
    <React.Fragment>
      {props?.from === "leads" && (
        <Row gutter={8} style={{ marginBottom: "24px" }}>
          <Col span={4} style={{ textAlign: "right" }}>
            Customer :
          </Col>
          <Col span={20}>
            {props?.name + " - " + props?.email + " - " + props?.phone}
          </Col>
        </Row>
      )}

      {props?.from === "quick-add" && isEditMode && (
        <Row gutter={8} style={{ marginBottom: "24px" }}>
          <Col span={4} style={{ textAlign: "right" }}>
            Customer :
          </Col>
          <Col span={20}>
            {props?.name + " - " + props?.email + " - " + props?.phone}
          </Col>
        </Row>
      )}

      <Form
        name="quickproposal-add"
        form={form}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 18,
        }}
        onFinish={
          props?.from === "quick-add" && !isEditMode ? addWithLead : onFinish
        }
        autoComplete="off"
        initialValues={{
          title: props?.title,
        }}
      >
        {props?.from === "quick-add" && !isEditMode && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Customer"
                name="customerId"
                rules={[
                  {
                    required: true,
                    message: "Please Select Customer!",
                  },
                ]}
              >
                <CustomersDropDown
                  {...props}
                  customerType={customerType}
                  handleCustomer={handleCustomer}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Form.Item label="Title" name="title">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Details"
              name="details"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                return data;
              }}
            >
              <CKEditor
                data={data?.details || ""}
                editor={ClassicEditor}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "190px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Image" name="imageURL">
              <UploadImg
                path="uploadfile"
                type="file-input"
                handleUpload={handleUpload}
                image={data?.imageURL}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Offer Price" name="offerPrice">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          wrapperCol={{
            offset: 4,
            span: 18,
          }}
          className="btn-row"
        >
          <Button
            type="primary"
            htmlType="submit"
            icon={!isEditMode ? <PlusOutlined /> : <EditOutlined />}
            loading={isLoading}
          >
            {!isEditMode ? "Add" : "Update"}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            icon={<WhatsAppOutlined />}
            onClick={handleShare}
          >
            Share
          </Button>

          {isEditMode && (
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={handlePreview}
            >
              Preview
            </Button>
          )}
        </Form.Item>
      </Form>

      <Row>
        <Col span={4}></Col>
        <Col span={18}>
          {quickReplies?.map((x, key) => (
            <div
              key={key}
              className="rounded p-3 mt-3"
              style={{
                background: "rgb(249, 252, 255)",
                border: "solid 1px rgb(217, 228, 236)",
              }}
            >
              <p style={{ fontSize: "1.0rem" }} className="mb-2">
                {x?.quickReply}
              </p>
              <span className="text-secondary small">
                {Date(x?.createdDate)} {Time(x?.createdDate)}
              </span>
            </div>
          ))}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AddQuickProposal;
