import React, { useEffect, useState } from "react";
import { Button, message, Form, Input, Row, Col, Switch } from "antd";
import { FileOutlined, EditOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import fetchData from "../../services/requester";
import UploadImg from "../../components/upload-img";
import { defaultSettings } from "../../helpers/default-settings";

const ManageSettings = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const getData = () => {
    let reqURL = "portal-settings";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data[0] || []);
        setDefaultData(data[0] || "");
      },
      "GET"
    );
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "portal-settings/update";
    let reqOBJ = values;

    reqOBJ.portalSettingsId = data?.portalSettingsId;

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);
      data?.success
        ? message.success("Website Settings updated successfully.")
        : message.error("Oops! Something went wrong.");
    });
  };

  const setDefaultData = (data) => {
    form.setFieldsValue({
      deals: data?.deals || defaultSettings?.deals,
      packages: data?.packages || defaultSettings?.packages,
      destinations: data?.destinations || defaultSettings?.destinations,
      testimonials: data?.testimonials || defaultSettings?.testimonials,
      whyus: data?.whyus || defaultSettings?.whyus,
      promotions: data?.promotions || defaultSettings?.promotions,
      dealsTitle: data?.dealsTitle || defaultSettings?.dealsTitle,
      dealsSubTitle: data?.dealsSubTitle || defaultSettings?.dealsSubTitle,
      packagesTitle: data?.packagesTitle || defaultSettings?.packagesTitle,
      packagesSubTitle:
        data?.packagesSubTitle || defaultSettings?.packagesSubTitle,
      destinationsTitle:
        data?.destinationsTitle || defaultSettings?.destinationsTitle,
      destinationsSubTitle:
        data?.destinationsSubTitle || defaultSettings?.destinationsSubTitle,
      whyusTitle: data?.whyusTitle || defaultSettings?.whyusTitle,
      whyusSubTitle: data?.whyusSubTitle || defaultSettings?.whyusSubTitle,
      testimonialsTitle:
        data?.testimonialsTitle || defaultSettings?.testimonialsTitle,
      testimonialsSubTitle:
        data?.testimonialsSubTitle || defaultSettings?.testimonialsSubTitle,
      copyright: data?.copyright || defaultSettings?.copyright,
      websiteLogo: data?.websiteLogo || "",
    });
  };

  const handleUpload = (websiteLogo) => {
    form.setFieldsValue({ websiteLogo });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <React.Fragment>
            <FileOutlined style={{ marginRight: "4px" }} /> Website Settings
          </React.Fragment>
        }
        ghost={false}
        style={{ border: "0px none" }}
      />

      <div className="web-settings">
        <Form
          name="web-settings"
          form={form}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{}}
        >
          <h4>Home Screen Modules</h4>
          <Row>
            <Col span={3}>
              <div style={{ display: "flex" }}>
                <Form.Item name="deals">
                  <Switch size="small" />
                </Form.Item>
                <label className="web-settings-label">Blogs</label>
              </div>
            </Col>
            <Col span={6}>
              <Form.Item label="Title" name="dealsTitle">
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Sub Title" name="dealsSubTitle">
                <Input placeholder="Sub Title" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={3}>
              <div style={{ display: "flex" }}>
                <Form.Item name="packages">
                  <Switch size="small" />
                </Form.Item>
                <label className="web-settings-label">Services</label>
              </div>
            </Col>
            <Col span={6}>
              <Form.Item label="Title" name="packagesTitle">
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Sub Title" name="packagesSubTitle">
                <Input placeholder="Sub Title" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={3}>
              <div style={{ display: "flex" }}>
                <Form.Item name="destinations">
                  <Switch size="small" />
                </Form.Item>
                <label className="web-settings-label">Case Studies</label>
              </div>
            </Col>
            <Col span={6}>
              <Form.Item label="Title" name="destinationsTitle">
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Sub Title" name="destinationsSubTitle">
                <Input placeholder="Sub Title" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={3}>
              <div style={{ display: "flex" }}>
                <Form.Item name="whyus">
                  <Switch size="small" />
                </Form.Item>
                <label className="web-settings-label">Why us</label>
              </div>
            </Col>
            <Col span={6}>
              <Form.Item label="Title" name="whyusTitle">
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Sub Title" name="whyusSubTitle">
                <Input placeholder="Sub Title" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ marginTop: "24px" }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<EditOutlined />}
              loading={isLoading}
            >
              Update Settings
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ManageSettings;
