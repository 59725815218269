import React from "react";
import { Link } from "react-router-dom";
import { Tabs } from "antd";
import ManagePromotions from "./manage-promotions";
import ManagePosts from "./manage-posts";
import ManageWhyus from "./manage-whyus";
import ManagePages from "./manage-pages";
import ManageSettings from "./manage-settings";

const ManageWebsite = (props) => {
  const items = [
    {
      key: "1",
      label: "Banner Promotions",
      children: <ManagePromotions />,
    },
    {
      key: "2",
      label: "Blogs",
      children: <ManagePosts category={"blog"} />,
    },
    {
      key: "3",
      label: "Services",
      children: <ManagePosts category={"service"} />,
    },
    {
      key: "4",
      label: "Case Studies",
      children: <ManagePosts category={"case-study"} />,
    },
    {
      key: "5",
      label: "Whyus",
      children: <ManageWhyus />,
    },
    {
      key: "6",
      label: "Pages",
      children: <ManagePages />,
    },
    {
      key: "7",
      label: "Website Settings",
      children: <ManageSettings />,
    },
  ];

  return (
    <div className="website-admin">
      <div>
        <Tabs
          defaultActiveKey="1"
          items={items}
          tabBarExtraContent={
            <Link key={1} type="primary" to={"/"} target="_blank">
              Visit Website
            </Link>
          }
        />
      </div>
    </div>
  );
};

export default ManageWebsite;
