import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import fetchData from "./services/requester-website";
import "./assets/css/style-website.css";
import WebsiteHeader from "./components/website/website-header";
import WebsiteFooter from "./components/website/website-footer";
import WebHome from "./screens/website/web-home";
import WebLoader from "./components/website/web-loader";
import PromotionDetails from "./screens/website/promotion-details";
import SignIn from "./screens/signin";
import PostDetails from "./screens/website/post-details";
import Posts from "./screens/website/posts";
import WebPage from "./screens/website/web-page";

function AppWebsite() {
  const [portal, setPortal] = useState("");
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const site = location.pathname.split("/")[2];

  const auth = () => {
    let reqURL = "auth?url=" + "bizvedaai";
    let reqOBJ = "";

    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          localStorage.setItem("website-token", data);
          getPortal();
        }
      },
      "GET"
    );
  };

  const getPortal = () => {
    let reqURL = "portal-settings";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setPortal(data[0] || []);
        localStorage.setItem("portalId", data[0]?.portalId);
        setLoading(false);
      },
      "GET"
    );
  };

  useEffect(() => {
    auth();
  }, []);

  return (
    <React.Fragment>
      {!loading && (
        <React.Fragment>
          <WebsiteHeader {...portal} setMenu={setMenu} />
          <div style={{ minHeight: "calc(100vh - 158px)" }}>
            <Routes>
              <Route key={1} path="/signin" element={<SignIn />} />
              <Route key={101} path={"/"} element={<WebHome {...portal} />} />
              <Route
                key={102}
                path={"/promotions/:id"}
                element={<PromotionDetails {...portal} />}
              />
              <Route
                key={103}
                path={"/:category/:id"}
                element={<PostDetails {...portal} />}
              />

              <Route
                key={104}
                path={"/:category"}
                element={<Posts {...portal} key={location.pathname} />}
              />
              <Route
                key={105}
                path={"/pages/:page"}
                element={
                  <WebPage
                    menu={menu}
                    key={location?.pathname + menu?.length}
                  />
                }
              />
            </Routes>
          </div>
          <WebsiteFooter {...portal} />
        </React.Fragment>
      )}
      {loading && <WebLoader />}
    </React.Fragment>
  );
}

export default AppWebsite;
