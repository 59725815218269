import React, { useEffect, useState } from "react";
import fetchData from "../../services/requester-website";
import { Link, useParams } from "react-router-dom";
import { imagePath } from "../../helpers/global";

const Posts = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState("");
  const params = useParams();

  const getData = () => {
    let reqURL = "posts";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data.filter((x) => x?.postCategory === getCategory()) || []);
        setLoading(false);
      },
      "GET"
    );
  };

  const getCategory = () => {
    const { category } = params;
    let type = "";

    if (category === "blogs") {
      type = "blog";
    }
    if (category === "services") {
      type = "service";
    }
    if (category === "case-studies") {
      type = "case-study";
    }

    return type;
  };

  useEffect(() => {
    getCategory();
    getData();
  }, []);

  return (
    <div className="home-posts">
      <div className="mt-5">
        <div className="container">
          {!loading && (
            <div className="row">
              {data?.map((x, key) => (
                <div key={key} className="col-lg-6">
                  <div className="home-posts-wrap">
                    <div className="row">
                      <div className="col-lg-4">
                        <img
                          src={imagePath() + x?.imageURL}
                          alt={x?.postName}
                        />
                      </div>
                      <div className="col-lg-8">
                        <h3 className="mt-2 mb-3">{x?.postName}</h3>
                        <h5>{x?.postSubTitle}</h5>
                      </div>
                    </div>

                    <Link to={"/" + getCategory() + "/" + x?.postId} />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Posts;
