import React, { useEffect, useState } from "react";
import {
  List,
  Row,
  Col,
  Button,
  Typography,
  Dropdown,
  Drawer,
  Tooltip,
  Avatar,
  Modal,
  message,
  Table,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  MenuOutlined,
  FilterOutlined,
  TableOutlined,
  UnorderedListOutlined,
  EyeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import fetchData from "../services/requester";
import CustomerAdd from "../components/customer-add";
import CustomerView from "../components/customer-view";
import CommonFilters from "../components/common-filters";

const Customers = () => {
  const [data, setData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const [isConfirmLoading, setConfirmLoading] = useState(false);
  const [isFiltersVisible, setFiltersVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [view, setView] = useState("grid");
  const { Title, Text } = Typography;

  const getData = () => {
    let reqURL = "customers";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data || []);
        setDefaultData(data || []);
        setLoading(false);
      },
      "GET"
    );
  };

  const menuItems = [
    {
      label: "View Customer",
      key: "view",
      icon: <EyeOutlined />,
    },
    {
      label: "Edit Customer",
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: "Delete Customer",
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];

  const handleMenuClick = (key, item) => {
    setDetails(item);
    switch (key) {
      case "view":
        setIsModalVisible(true);
        break;

      case "edit":
        setIsEditMode(true);
        showDrawer();
        break;

      case "delete":
        setConfirmVisible(true);
        break;

      default:
        break;
    }
  };

  const handleClick = (item) => {
    setIsModalVisible(true);
    setDetails(item);
  };

  const onClose = () => {
    setIsEditMode(false);
    setVisible(false);
  };

  const onFiltersClose = () => {
    setFiltersVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const showFiltersDrawer = () => {
    setFiltersVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAdd = () => {
    message.success(
      !isEditMode ? "Record added successfully" : "Record updated successfully"
    );
    setVisible(false);
    setIsEditMode(false);
    getData();
  };

  const handleDelete = () => {
    setConfirmLoading(true);
    const { customerId } = details;
    let reqURL = "customers/delete/" + customerId;
    let reqOBJ = "";
    fetchData(reqURL, reqOBJ, (data) => {
      setConfirmVisible(false);
      setConfirmLoading(false);
      if (data?.success) {
        message.success("Record deleted successfully.");
        setLoading(true);
        getData();
      } else {
        message.error(data?.title || "Oops! Something went wrong.");
      }
    });
  };

  const changeView = () => {
    setView(view === "grid" ? "list" : "grid");
  };

  const handleFilters = (filters) => {
    setFiltersVisible(false);

    const filteredData = defaultData?.filter(
      ({ customerType, name, email, phone }) =>
        (!filters?.customerType ||
          customerType
            ?.toLowerCase()
            ?.includes(filters?.customerType?.toLowerCase())) &&
        (!filters?.name ||
          name?.toLowerCase()?.includes(filters?.name?.toLowerCase())) &&
        (!filters?.email ||
          email?.toLowerCase()?.includes(filters?.email?.toLowerCase())) &&
        (!filters?.phone || phone?.includes(filters?.phone))
    );

    setData(filteredData);
  };

  const columns = [
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>{text}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text, record) => record?.countryCode + text,
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div className="d-flex align-items-center justify-content-center">
          <Dropdown
            menu={{
              items: menuItems,
              onClick: (e) => handleMenuClick(e.key, record),
            }}
            icon
          >
            <Button className="reset-icon-btn">
              <MenuOutlined />
            </Button>
          </Dropdown>
        </div>
      ),
      width: 50,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <React.Fragment>
            <UserOutlined style={{ marginRight: "4px" }} /> Customers
          </React.Fragment>
        }
        extra={[
          <Tooltip title="Change View" placement="bottom" key={1}>
            <Button
              key={1}
              type="default"
              icon={
                view === "grid" ? <TableOutlined /> : <UnorderedListOutlined />
              }
              onClick={changeView}
            >
              Views
            </Button>
          </Tooltip>,
          <Tooltip title="Filters" placement="bottom" key={2}>
            <Button
              key={2}
              type="default"
              icon={<FilterOutlined />}
              onClick={showFiltersDrawer}
            >
              Filters
            </Button>
          </Tooltip>,
          <Button
            key={3}
            type="primary"
            icon={<PlusOutlined />}
            onClick={showDrawer}
          >
            Add Customer
          </Button>,
        ]}
        ghost={false}
      />

      {view === "grid" && (
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={data}
          className="general-list"
          renderItem={(item) => (
            <List.Item
              actions={[
                <Dropdown
                  menu={{
                    items: menuItems,
                    onClick: (e) => handleMenuClick(e.key, item),
                  }}
                  icon
                >
                  <Button
                    style={{
                      border: "0px",
                      background: "none",
                      boxShadow: "none",
                    }}
                  >
                    <MenuOutlined />
                  </Button>
                </Dropdown>,
              ]}
            >
              <Row
                style={{ width: "100%", cursor: "pointer" }}
                onClick={() => handleClick(item)}
              >
                <Col flex="auto" className="lead-col-1">
                  <Row gutter={16}>
                    <Col
                      flex="none"
                      style={{ display: "flex", alignItems: "center" }}
                      className="lead-avatar"
                    >
                      <Avatar size={52} shape="square">
                        <span
                          style={{
                            fontSize: "18px",
                            textTransform: "uppercase",
                            lineHeight: "18px",
                          }}
                        >
                          {item?.name?.charAt(0)}
                        </span>
                      </Avatar>
                    </Col>
                    <Col flex="auto">
                      <Title
                        level={5}
                        style={{
                          textTransform: "capitalize",
                          margin: "0px 0px 6px 0px",
                        }}
                      >
                        {item?.name}
                      </Title>
                      <Text type="secondary">
                        <MailOutlined /> {item?.email}
                      </Text>
                      <Text type="secondary" style={{ marginLeft: "16px" }}>
                        <PhoneOutlined /> {item?.countryCode + item?.phone}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      )}

      {view === "list" && (
        <Table
          columns={columns}
          rowKey={(record) => record?.customerId}
          dataSource={data || []}
          loading={loading}
          size="small"
        />
      )}

      <Drawer
        title={!isEditMode ? "Add Customer" : "Update Customer"}
        placement="right"
        onClose={onClose}
        open={visible}
        size={"large"}
      >
        <CustomerAdd
          isEditMode={isEditMode}
          {...details}
          key={visible}
          handleAdd={handleAdd}
        />
      </Drawer>

      <Drawer
        title="Filters"
        placement="right"
        onClose={onFiltersClose}
        open={isFiltersVisible}
      >
        <CommonFilters handleFilters={handleFilters} screen={"customers"} />
      </Drawer>

      <Modal
        title={
          <span style={{ textTransform: "capitalize" }}>{details?.name}</span>
        }
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        centered
        width={720}
        key={isModalVisible}
      >
        <CustomerView {...details} key={isModalVisible} />
      </Modal>

      <Modal
        title={"Confirm Delete"}
        open={isConfirmVisible}
        onCancel={() => setConfirmVisible(false)}
        centered
        width={320}
        footer={[
          <div style={{ textAlign: "center" }} key="delete">
            <Button
              type="primary"
              loading={isConfirmLoading}
              danger
              onClick={() => handleDelete()}
            >
              Confirm
            </Button>
          </div>,
        ]}
      >
        <div style={{ margin: "16px 0px" }}>
          Are you sure to delete this Item?
        </div>
      </Modal>
    </div>
  );
};

export default Customers;
