import React, { useState, useEffect } from "react";
import fetchData from "../../services/requester-website";
import { imagePath } from "../../helpers/global";
import { defaultSettings } from "../../helpers/default-settings";
import WhyUsImage from "../../assets/images/ai/whyus.jpeg";

const HomeWhyus = (props) => {
  const [data, setData] = useState([]);

  const getData = () => {
    let reqURL = "whyus";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        data && setData(data?.filter((x) => x?.showOnHome) || []);
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    data &&
    data.length > 0 && (
      <div className="home-whyus">
        <div className="row">
          <div className="col-lg-6">
            <img
              src={WhyUsImage}
              alt=""
              style={{
                width: "90%",
                borderRadius: "16px",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="col-lg-6">
            <div>
              <h2 className="home-title">
                {props?.whyusTitle || defaultSettings?.whyusTitle}
              </h2>
              <p className="home-sub-title">
                {props?.whyusSubTitle || defaultSettings?.whyusSubTitle}
              </p>
              <div className="row">
                {data?.map(
                  (x, key) =>
                    key < 4 && (
                      <div key={key} className="col-lg-12">
                        <div
                          className="home-whyus-wrap"
                          style={{
                            textAlign: "left",
                            boxShadow: "none",
                            border: "0px none",
                            padding: "0px",
                          }}
                        >
                          <h3>{x?.title}</h3>
                          <p>{x?.subTitle}</p>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default HomeWhyus;
